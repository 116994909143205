import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


import Error from "../pages/error";

// components
import Layout from "./Layout";

import NotFoundPage from "../pages/404";
import LoginPage from "../pages/login";
import SigninPage from "../pages/signinnew";
import SigninPage2 from "../pages/signin";
import MainPage from "../pages/blockproducersmainHome4";
import AboutPage from "../pages/blockproducersabout";
import Signup from "../pages/signup-blockproducers";
import Intelligence from "../pages/Intelligence";
import Prospecting from "../pages/Prospecting";
import Home2 from "../pages/home2";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <Router>
      <Switch>
         <Route exact path="/" component={MainPage}></Route>
           <Route exact path="/404"  component={NotFoundPage}></Route>
           <Route exact path="/login2"  component={SigninPage2}></Route> 
           <Route exact path="/login"  component={SigninPage}></Route> 
            <Route exact path="/automation"  component={Intelligence}></Route> 
              <Route exact path="/prospecting"  component={Prospecting}></Route> 
             <Route exact path="/about"  component={AboutPage}></Route>
             <Route exact path="/get-started/signup"  component={Signup}></Route>
           <Route exact path="/home2"  component={Home2}></Route> 
             <Route exact path="/feed"  component={Layout}></Route>
          
        
         <Route component={Error} />
      </Switch>
    </Router>
  );
 // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
 
}
