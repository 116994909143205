import React, { useState } from "react";
import '../../pages/app.css';

export default function Security(props) {
    function shoot(e) {
        var el = document.getElementById('op1');
        el.classList.remove('_1fqn0');
        var el2 = document.getElementById('op2');
        el2.classList.remove('_1fqn0');
        e.currentTarget.classList.toggle('_1fqn0')
        }
  
        function shoot1(e) {
  
          var el = document.getElementById('op');
          el.classList.remove('_1fqn0');
          var el2 = document.getElementById('op2');
          el2.classList.remove('_1fqn0');
          e.currentTarget.classList.toggle('_1fqn0')
  
       }
          function shoot2(e) {
  
              var el = document.getElementById('op1');
              el.classList.remove('_1fqn0');
              var el2 = document.getElementById('op');
              el2.classList.remove('_1fqn0');
              e.currentTarget.classList.toggle('_1fqn0')
              
              }

              function openmenu(e){
                // alert(222); 
                 var el = document.getElementById('sidebarmenu');
                 el.classList.toggle('opensidebar')
                 var el2 = document.getElementsByClassName('_2THv1')[0]
                 el2.classList.toggle('_8Oh3q') 
                 var el21 = document.getElementsByClassName('_2Ei7e')[0]
                 el21.classList.toggle('addtra')
             } 
             
             function openconfig(e){
                var el2 = document.getElementsByClassName('configdrop')[0]
                el2.classList.toggle('show') 
                var el2 = document.getElementsByClassName('menuwadd')[0]
                el2.classList.toggle('dropconfigsub') 
             }
             function submenuchnage(e){
        
                var url = '/trial/'+ e.target.value;
                window.location.href=url;
                
            }

  return (
    <>
         <div class="_2nR6t">
                <div class="_2NS96">
                    <div class="_1fhil"></div>
                    <div class="_1RiPb"></div>
                </div>
                <div class="_2Qk4f">
                   
                    <div class="_2RHK6 _14wL6">
                     
                    <div class="_2kHSr _2jBtQ _3z7OM _3Uosj _1K3hm" id="sidebarmenu" >
                            <div class="_3hcLl" >
                                <div class="_1jc8P">
                                    <div class="_3iyER">
                                        <div class="ljYKJ logoname">
                                           TBD
                                        </div>
                                        <div class="_13epd">
                                            <div class="QPQ6k">
                                                <div class="_29AwW">
                                                
    
                                                    
                                                    <div class="_ewpg _1fqn0" id="op2" onClick={shoot2}>
                                                        <div class="ORPh0">
                                                            <div class="_1ObBr">
                                                                <svg width="24" height="24" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M64 8C94.9279 8 120 33.0721 120 64C120 94.9279 94.9279 120 64 120C33.0721 120 8 94.9279 8 64C8 33.0721 33.0721 8 64 8ZM64 16C37.4903 16 16 37.4903 16 64C16 90.5097 37.4903 112 64 112C90.5097 112 112 90.5097 112 64C112 37.4903 90.5097 16 64 16ZM78.5743 35.2112C75.545 31.7513 70.6419 30 63.9998 30C57.3577 30 52.4546 31.7513 49.4253 35.2096C46.3627 38.7031 46.2269 42.8501 46.2968 44.3793L46.3158 44.6893L46.3302 44.8526V52.5272L46.1784 52.7111C45.34 53.768 44.8723 55.0764 44.8723 56.4229V62.2525L44.8765 62.4781C44.941 64.2028 45.7422 65.7994 47.0748 66.8915C48.1945 71.2795 50.3103 74.7708 51.5572 76.5664L51.8575 76.9903L52.1587 77.3978V82.1568L52.1546 82.3312C52.0943 83.6048 51.376 84.7601 50.2488 85.3767L35.2522 91.4692L34.9421 91.6451C34.7357 91.767 34.53 91.8979 34.3244 92.0371L34.0436 92.2325L34 92.2711C41.4222 98.9417 53.2372 104 63.9998 104C74.7626 104 86.5779 98.9414 94 92.2705L93.9776 92.2512L93.796 92.4147C93.3128 92.0809 92.8262 91.7816 92.3282 91.5316L76.4112 85.5707L76.2575 85.4893C75.0987 84.8409 74.3862 83.6313 74.3862 82.2933V77.4307L74.5816 77.1857C74.8449 76.8507 75.1189 76.4832 75.4003 76.0872C77.2018 73.5416 78.6466 70.7099 79.7002 67.6627C81.7827 66.6728 83.1273 64.5975 83.1273 62.2541V56.4246L83.1224 56.1874C83.0679 54.8461 82.5526 53.5555 81.6693 52.5289V44.8543L81.6937 44.5479C81.7788 43.1753 81.757 38.8417 78.5743 35.2112Z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <div class="W5zWj">
                                                                <div class="wyuh-">
                                                                    <h3 class="_1VrMK">Account Settings</h3>
                                                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="UyUWa">
                                                                        <path
                                                                            d="M10.9996 7.50031C10.6104 7.88958 9.97933 7.88992 9.58963 7.50107L5.70502 3.62492L1.8204 7.50108C1.4307 7.88992 0.799674 7.88958 0.410401 7.50031C0.0208283 7.11073 0.0208285 6.47911 0.410402 6.08954L5.70502 0.794922L10.9996 6.08954C11.3892 6.47911 11.3892 7.11073 10.9996 7.50031Z"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                <p class="_18u0w">Settings and more.</p>
                                                            </div>
                                                        </div>
                                                        <div class="_2zFLh submenu">
                                                <a class="_3KvtS _2zJ8_ "  href="#">Account settings</a>
                                                <a class="_3KvtS _2zJ8_ "  href="/workpoint/feed">Feed</a>
                                                        
                                                    </div>
                                                        <div class="_3al5t"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        


                    <div class="_29JGy">
                    <div data-test="header" class="_3dJ9M">
				    																								<div class="_3daw4">
        <button type="button" tabindex="0" class="_3-mad _2Ei7e" onClick={openmenu}>
            <div class="_2THv1">
                <div class="_2jRz9"><div></div></div>
            </div>
        </button>

        
    </div>
				    																																																																												<div class="_3VCAu">
        <a href="#" class="XwTk5 _3-mad">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="rJl7m">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM4 12C4 7.58999 7.59 3.99999 12 3.99999C16.41 3.99999 20 7.58999 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12ZM10 10H8C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 11.2829 15.21 11.9733 14.4408 12.6455C13.711 13.2833 13 13.9046 13 15H11C11 13.1787 11.9421 12.4566 12.7704 11.8217C13.4202 11.3236 14 10.8792 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10Z"
                ></path>
            </svg>
        </a>
        <a href="#" class="XwTk5 _3-mad">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="rJl7m">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.333 6.667A2.666 2.666 0 0018.667 4H5.333a2.675 2.675 0 00-2.666 2.667v18.666C2.667 26.8 3.867 28 5.333 28h13.334c1.48 0 2.666-1.2 2.666-2.667v-4h-2.666v4H5.333V6.667h13.334v4h2.666v-4zm3.858 13.677a1.31 1.31 0 01-1.906-1.799l1.13-1.212H13.334a1.333 1.333 0 110-2.666h11.083l-1.131-1.212a1.31 1.31 0 011.906-1.799L29.333 16l-4.142 4.344z"
                ></path>
            </svg>
        </a>
    </div>
				</div>

                        
<div class="mt-4 pri">
    <div class="container">

    <div class="mb-3 row">
            <div class="col">
                <div class="card1">
                    <div class="card-body linkhead">
                        <h6 class="titletop">Prospect Updates</h6>
                        <a class="headerlinks" href="#">Engage <img src="assets/images/speech-balloon_1f4ac.png" width="18" height="18" alt="" class="mr7" /></a>
                        <a class="headerlinks" href="#">Postion Change <img src="assets/images/clapping-hands_1f44f.png" width="18" height="18" alt="" class="mr7" /></a>
                        <a class="headerlinks" href="#">Nurturing <img src="assets/images/seedling_1f331.png" width="18" height="18" alt="" class="mr7" /></a>
                       
                    </div>

                    <div class="mobile-sub-menu">
                        <select name="submenu" id="submenu" class="form-control" onChange={submenuchnage}>
                        <option>Select Submenu</option>
                        <option>Engage</option>
                        <option value="#">Postion Change</option>
                        <option value="#">Nurturing</option>
                      
                        </select>
                    </div>
                    
                </div>
            </div>
        </div>


        <div class="mb-3 row">
            <div class="col col-lg-10">
                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/logouser.png" width="70" height="70" alt="" class="mr7" /> 
                        <div className="nametitle">
                           <p> <strong> Rex Humphries </strong> .1st </p>
                           <p class="ptextl">SVP at interlincx Media <br/>3w</p>
                        </div>
                        
                        <div class="pro1 feeddesc">
                           Congratulate Rex for starting a new Postion as SVP at Interlincx Media
                        </div>
                        
                    </div>
                   
                </div>
            </div>
              <div class="col col-lg-2">
                <div class="card">
                    <div class="card-body cbody">
                        <div className="innercbody">
                        <img src="assets/images/timeline.png" width="22" height="22" alt="" class="mr7" /> Timeline </div>
                        <hr/>
                        <ul className="timelinedrop">
                            <li>
                               <span>3</span> Need Action</li>
                            <li>Last Week</li>
                            <li>October</li>
                            <li>September</li>
                        </ul>
                        
                        
                    </div>
                   
                </div>
            </div>
        </div>

     <div class="mb-3 row">
            <div class="col col-lg-10">
                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/logouser.png" width="70" height="70" alt="" class="mr7" /> 
                        <div className="nametitle">
                           <p> <strong> Rex Humphries </strong> .1st </p>
                           <p class="ptextl">SVP at interlincx Media <br/>3w</p>
                        </div>
                        
                        <div class="pro1 feeddesc">
                           Congratulate Rex for starting a new Postion as SVP at Interlincx Media
                        </div>
                        
                    </div>
                   
                </div>
            </div>
</div>
<div class="mb-3 row">
            <div class="col col-lg-10">
                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/logouser.png" width="70" height="70" alt="" class="mr7" /> 
                        <div className="nametitle">
                           <p> <strong> Rex Humphries </strong> .1st </p>
                           <p class="ptextl">SVP at interlincx Media <br/>3w</p>
                        </div>
                        
                        <div class="pro1 feeddesc">
                           Congratulate Rex for starting a new Postion as SVP at Interlincx Media
                        </div>
                        
                    </div>
                   
                </div>
            </div>
</div><div class="mb-3 row">
            <div class="col col-lg-10">
                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/logouser.png" width="70" height="70" alt="" class="mr7" /> 
                        <div className="nametitle">
                           <p> <strong> Rex Humphries </strong> .1st </p>
                           <p class="ptextl">SVP at interlincx Media <br/>3w</p>
                        </div>
                        
                        <div class="pro1 feeddesc">
                           Congratulate Rex for starting a new Postion as SVP at Interlincx Media
                        </div>
                        
                    </div>
                   
                </div>
            </div>
</div><div class="mb-3 row">
            <div class="col col-lg-10">
                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/logouser.png" width="70" height="70" alt="" class="mr7" /> 
                        <div className="nametitle">
                           <p> <strong> Rex Humphries </strong> .1st </p>
                           <p class="ptextl">SVP at interlincx Media <br/>3w</p>
                        </div>
                        
                        <div class="pro1 feeddesc">
                           Congratulate Rex for starting a new Postion as SVP at Interlincx Media
                        </div>
                        
                    </div>
                   
                </div>
            </div>
</div>
        
    </div>
</div>

</div>
                    </div>
                </div>
                
            </div>

        


         </>
  );
}

