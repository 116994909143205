import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';

class SignInPage extends Component {
   
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){

        
    return(
         <div>

            <div class="logosign">
                <a href="/">
                      <img src="/assets/images/P-Icon.png" class="desklogo" />
                  </a>
            </div>
            <div class="whitefade"></div>

             <div class="signinpage gu newsignpage">
             

             <section class="cklsign firststepsign ">
    <div class="pklsi">
        <div class="iopsi">
             <div class="whitefade inner"></div>
            <header class="uiopj">
               

                

                <h1 class="c3fa29956 c926e5028">Log in to Workpoint</h1>
               

                
            </header>
           <hr/>

            <div class="c439d90a0 c37cd0ac8">
            <form  novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group email-form-g">
                                
                                <input type="email" name="e" required  class="form-control emaillogin" placeholder="Email address" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"   class="btn btn-lg btn-flat btn-block btn-primary firststepcon">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="ulp-alternate-action _alternate-action __s16nu9">
                    <p class="c76075b34 c2fad6637 ca7b07586">
                        Don't have an account?
                     
                         <a
                        class="c82c0b9b3 c8061fbcb"
                        href="/get-started/signup"
                    >
                        Sign up
                    </a>
                    </p>
                </div>

               

           

                
            </div>
        </div>
    </div>
</section>
 

<section class="cklsign laststepsign">
<div class="pklsi">
    <div class="iopsi">
        <div class="whitefade inner2"></div>
        <header class="uiopj">
            <div
                title="HashiCorp"
                id="custom-prompt-logo"
                
            ></div>

            

            <h1 class="c3fa29956 c926e5028">Enter Your Password</h1>

            
        </header>

        <div class="c439d90a0 c37cd0ac8">
        <form action="verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                <div class="signupForm-container">
                    <div class="signupForm-row">
                    <div class="form-group">
                            
                            <input type="email" name="e1" required  class="form-control emaillogin2" placeholder="Email address" id="work-email2" />
                        </div>
                        <div class="form-group">
                            
                            <input type="password" name="e2" required  class="form-control" placeholder="Password" id="work-email" />
                        </div>
                        <div class="form-group ckl">
                        <a
                        class="c82c0b9b3 c8061fbcb ck"
                        href="#"
                    >
                        Forgot password?
                    </a> </div>

                        <div class="submit-btn">
                            <button id="go-to-nextStep" class="btn btn-lg btn-flat btn-block btn-primary">Continue</button>
                        </div>
                       
                    </div>
                </div>
            </form>
            <div class="ulp-alternate-action _alternate-action __s16nu9">
                <p class="c76075b34 c2fad6637 ca7b07586">
                    Don't have an account?
                    <a
                        class="c82c0b9b3 c8061fbcb"
                        href="/get-started/signup"
                    >
                        Sign up
                    </a>
                </p>
            </div>

           

            
        </div>
    </div>
</div>
</section>


             </div>




 </div>
    );
}

}

export default SignInPage;
